import React, { useState, useContext, useEffect } from "react";
import { useTranslation, Trans } from 'react-i18next';
import { Button, Container, Dropdown, DropdownButton, Form } from "react-bootstrap"
import { useNavigate } from 'react-router-dom';
import { client } from 'websocket';
import { LinkContainer } from 'react-router-bootstrap'
import ReactDOMServer from 'react-dom/server';

import { UserContext, isBrand } from "../../components";

import { BsBuildingsFill } from "react-icons/bs";
import { SlLogout, SlUser } from 'react-icons/sl';
import { BiAddToQueue } from 'react-icons/bi';
import { FaRegStar } from "react-icons/fa6";
import { FaStar } from "react-icons/fa";
import { IoIosStarOutline, IoIosStar } from "react-icons/io";
import { FaCar, FaBell } from 'react-icons/fa';
import { RiLayoutMasonryFill, RiArrowDropRightLine } from 'react-icons/ri';
import { RxLockClosed, RxLockOpen2, RxReload } from 'react-icons/rx'
import { IoStatsChartSharp, IoSettings } from 'react-icons/io5'
import { FaRoute } from 'react-icons/fa'
import { FaTachographDigital } from "react-icons/fa6";
import { IoWarningOutline, IoInformationCircleOutline } from "react-icons/io5";
import { transform } from "ol/proj";

import './Menu.css'

export const menuIcon = (code, classN) => {
    switch (code) {
        case 'overview':
            return isBrand('alfakomtemp') ? <BsBuildingsFill /> : <FaCar className={classN ? classN : ''} />
        case 'rides':
            return <FaRoute className={classN ? classN : ''} />
        default:
            return <div></div>
    }
}

export default function Menu() {
    const { t, i18n } = useTranslation('translation');
    document.title = i18n.t('widget.config')
    const navigate = useNavigate();
    const colorIcon = getComputedStyle(document.documentElement).getPropertyValue('--bs-primary');
    const { uiConfig, setUiConfig, clientRoles } = useContext(UserContext);
    let navbar = useContext(UserContext).uiConfig.navbar;

    const getIndexNavbar = (code) => {
        switch (code) {
            case 'overview': return 1
            case 'rides': return 2
        }
    }

    const clickHandler = (e) => {
        const item = navbar.filter(i => i.code === e)[0]
        if (!item) navbar.push({ code: e, index: getIndexNavbar(e), url: '/' + e })
        else navbar = navbar.filter(i => i.code !== e)
        navbar.sort((a, b) => a.index - b.index)
        setUiConfig({ ...uiConfig, navbar: navbar })
    }

    let modulAvail = [];
    if (clientRoles.some(i => i.type.symbol === 'data_curator') || clientRoles.some(i => i.type.symbol === 'view_events')) modulAvail.push('overview');
    if (clientRoles.some(i => i.type.symbol === 'data_curator') || clientRoles.some(i => i.type.symbol === 'view_history')) modulAvail.push('rides');


    const Modul = ({ code }) => {
        return (
            < >
                <Button variant="light" style={{ height: '150px' }} className="position-relative w-25 shadow mb-5 bg-body rounded mx-3 mx-lg-5 py-2 block" onClick={() => navigate('/' + code)} >
                    <a className="fs-6 fw-bold text-center">{t('widget.' + code)}</a>
                    <div className="position-absolute top-0 end-0 me-2 mt-2 cursorpointer" onClick={(e) => { e.stopPropagation(); clickHandler(code) }} >
                        {navbar.some(i => i.code === code) ? <IoIosStar color={colorIcon} className="fs-5" /> : <IoIosStarOutline color={colorIcon} className="fs-5" />}
                    </div>
                    {code === 'overview' ? <div className="text-start">
                        <a onClick={(e) => { e.stopPropagation(); navigate('/'+code+'?fullScreen=MapView') }}>Mapa</a>
                        <div>Seznam objektů</div>
                    </div> : <div className="text-start">
                    </div>}
                    <div className="position-absolute bottom-0 end-0 me-2 mb-2 halfIcon">{menuIcon(code, 'fs-1')}</div>
                </Button>

            </>

        )
    }

    return (
        <div className="m-3">
            <h3 className="text-center mb-5">Nástroje</h3>
            <div className="mx-1 mx-lg-3 bg-white d-flex align-items-center justify-content-center menu">
                {modulAvail.map(m => <Modul code={m} key={m} />)}
            </div>
        </div>
    )
}