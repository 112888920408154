import React, {  lazy } from 'react';

import Login from "./Login";
import Overview from "./Overview/Overview";
import Rides from "./Rides/Rides";
import Mapview from "./Map";
import Tacho from "./Tacho/Tacho";
import Time from "./Time";
import Error from "./Error";
import Profile from "./Profile/Profile"
import Config from "./Config/Config"
import Equipments from "./Config/Equipments"
import Dashboards from "./Config/Dashboards"
import Fences from "./Config/Fences"
import Reports from "./Reports/Reports"
// import CarRental from "./CarRental/CarRental"
// import Admin from "./Admin/Admin"
// import SCard from "./Admin/sCard"
// import AllClients from "./Admin/AllClients"
// import DDDcheck from "./Admin/DDDcheck"
// import Menu from "./Menu"

const CarRental = lazy(() => import("./CarRental/CarRental"));
const Admin = lazy(() => import("./Admin/Admin"));
const SCard = lazy(() => import("./Admin/sCard"));
const AllClients = lazy(() => import("./Admin/AllClients"));
const DDDcheck = lazy(() => import("./Admin/DDDcheck"));
const Menu = lazy(() => import("./Menu/Menu"));

export {
	Login,
	Overview,
	Rides,
	Mapview,
	Tacho,
	Time,
	Error,
	Profile,
	Config,
	Equipments,
	Dashboards,
	Reports,
	Fences,
	CarRental,
	Admin,
	SCard,
	AllClients,
	DDDcheck,
	Menu
};