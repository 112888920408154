import { useState, useEffect, useCallback } from "react";
import { client } from 'websocket';

export function useWindowSize() {
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { width: innerWidth, height: innerHeight };
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  return windowSize
}

export const setQuery = (key, value, navigate, location) => {
  const searchParams = new URLSearchParams(location.search);
  searchParams.set(key, value);
  navigate(`?${searchParams.toString()}`);
};

export const clearQuery = (navigate) => {
  navigate({ search: '' });
};

export const getQuery = (value, location) => {
  const searchParams = new URLSearchParams(location.search);
  return searchParams.get(value);
};

export const isClient = (name, costumer) => {
  if (costumer) {
    const clientNumber = String(costumer.match(/\[(.*?)\]/)[1]).padStart(5, '0')
    const checkWs = (ws) => {
      return client.url === `wss://${ws}.logiscare.com/`
    }

    switch (name) {
      case 'ceskyRozhlas':
        if (clientNumber === '11598' && checkWs('ws01')) return true
        // if (client.url === `ws://ws88.logiscare.com/`) return true
        // if (clientNumber === '00001' && checkWs('ws99')) return true
        break;
      case 'develop':
        if (checkWs('ws99') || checkWs('ws00') || process.env.NODE_ENV === 'development') return true
        break;
      case 'geoTest':
        if (clientNumber === '06065' && checkWs('ws02')) return true
        break;
      default: return false
    }
  }
  return false
};

export const isBrand = (name) => {
  return process.env.REACT_APP_BRAND === name
};

export const useKeyboardShortcut = (shortcutAction, config) => {
  const targetElement = config.shortcutTarget || document;

  const eventHandler = useCallback((e) => {
    // console.log(e);
    const { code, ctrlKey, altKey, shiftKey } = e;
    if (config.code !== code) return;
    if (config.ctrlKey && !ctrlKey) return;
    if (config.shiftKey && !shiftKey) return;
    if (config.altKey && !altKey) return;

    e.preventDefault();  // Zabránění výchozímu chování prohlížeče
    shortcutAction(e);
  }, [shortcutAction, config]);

  useEffect(() => {
    targetElement.addEventListener('keydown', eventHandler);
    return () => targetElement.removeEventListener('keydown', eventHandler);
  }, [targetElement, eventHandler]);
}

export const generatePassword = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let password = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    password += characters[randomIndex];
  }
  return password;
};

export const masterUser = ['michal.dusek', 'jakub.stefan', 'tom', 'danielap', 'jan', 'radka.duskova','milan.kaspar','lumir.olbrich'];

export function getJSONParse(jsonString) {
  try {
    return JSON.parse(jsonString);  
  } catch (e) {
    return false;
  }
}