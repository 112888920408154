import i18n from '../dictionary/i18n';

import { FiMaximize2, FiMinimize2 } from 'react-icons/fi'
import { GrFormClose } from 'react-icons/gr'

export const checkMount = (findItem, layouts) => {
    const res = layouts.lg.find(i => {
        return i.i === findItem
    })
    if (res) return true
    else return false
}

export const newLayots = (layoutItem, layouts) => {
    return {
        lg: layouts.lg.map((item) => {
            if (item.y === layoutItem.y) return { ...item, y: item.y + 1 }
            else return item
        }).concat(layoutItem),
        md: layouts.md.map((item) => {
            if (item.y === layoutItem.y) return { ...item, y: item.y + 1 }
            else return item
        }).concat(layoutItem),
        sm: layouts.sm.map((item) => {
            if (item.y === layoutItem.y) return { ...item, y: item.y + 1 }
            else return item
        }).concat(layoutItem),
        xs: layouts.xs.map((item) => {
            if (item.y === layoutItem.y) return { ...item, y: item.y + 1 }
            else return item
        }).concat(layoutItem),
    }
}

export const filterLayouts = (removeItem, layout) => {
    return {
        lg: (layout.lg.filter((item) => item.i !== removeItem)),
        md: (layout.md.filter((item) => item.i !== removeItem)),
        sm: (layout.sm.filter((item) => item.i !== removeItem)),
        xs: (layout.xs.filter((item) => item.i !== removeItem))
    }
}

export const breakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }

export const cols = { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }

export const margin = {
    lg: [10, 10],
    md: [5, 5],
    sm: [5, 5],
    xs: [5, 5],
}

export const rowHeight = 25;

export const calcMaxH = (layout, heightWindow) => {
    const newLayout = {}

    const getValouBreak = (br) => {
        switch (br) {
            case 'lg': return [37, 8];
            case 'md': return [35, 4];
            case 'sm': return [35, 3];
            case 'xs': return [35, 3];
        }
    }

    Object.entries(layout).forEach(([b, l]) => {
        if (l.every(i => i.y === l[0].y)) {
            if (b === 'xxs') newLayout[b] = l
            else {
                const newHeight = Math.floor(((heightWindow - getValouBreak(b)[0]) * (1 / window.devicePixelRatio)) / ((rowHeight + getValouBreak(b)[1]) * (1 / window.devicePixelRatio)))
                newLayout[b] = l.map(i => { return { ...i, h: newHeight } })
            }
        } else newLayout[b] = l
    })

    Object.entries(newLayout).forEach(([b, l]) => {
        let lastItem = 0;
        let countRows = [];
        l.forEach(i => {
            if (i.y > lastItem) lastItem = i.y;
            if (!countRows.includes(i.y)) countRows.push(i.y);
        })
        if (lastItem > 0 && b !== 'xxs' && countRows.length < 3) {
            const maxHeight = Math.floor(((heightWindow - getValouBreak(b)[0]) * (1 / window.devicePixelRatio)) / ((rowHeight + getValouBreak(b)[1]) * (1 / window.devicePixelRatio)))
            newLayout[b] = l.map(i => {
                if (i.y === lastItem) return { ...i, h: (maxHeight - lastItem) }
                else return i
            })
        } else newLayout[b] = l
    })
    return newLayout
}

export const checkName = (name) => {
    if (name === 'MapView') return i18n.t('widget.map');
    if (name === 'RidesView') return i18n.t('widget.rides');
    if (name === 'DevicesList') return i18n.t('widget.devicesList');
    if (name === 'DeviceDetail') return i18n.t('widget.deviceDetail');
    if (name === 'DeviceReport') return i18n.t('widget.deviceReport');
    if (name === 'DDDViewVehicle') return i18n.t('widget.dddFileVehicle');
    if (name === 'DDDViewDrivers') return i18n.t('widget.dddFileDrivers');
    if (name === 'DDDViewListFiles') return i18n.t('widget.dddFilesList');
    if (name === 'DriverCheck') return i18n.t('widget.DriverCheck');
}

const MaxBtn = ({ val, set, resize, name, mount }) => {
    if (val) {
        if (mount) return (
            < FiMinimize2
                title={i18n.t('widget.minimize')}
                className="cursorpointer fs-6 float-end me-1 mt-1"
                onClick={() => { set(); resize() }}
            />)
        else return (
            < GrFormClose
                title={i18n.t('widget.close_w')}
                className="cursorpointer fs-4 float-end"
                onClick={() => { set(); resize() }}
            />)
    }
    else return (<FiMaximize2
        title={i18n.t('widget.maxmize')}
        className="cursorpointer fs-6 float-end me-1 mt-1"
        onClick={() => {
            set(name); resize()
        }}
    />)
}

const CloseBtn = ({ lock, max, name, remove, force, height }) => {
    if (!(lock || max)) return (
        <GrFormClose
            title={i18n.t('widget.close_w')}
            className="cursorpointer fs-4 float-end"
            onClick={() => {
                remove(name);
                height = null;
                force({});
            }} />
    )
}

export const HeaderGrid = ({ lock, max, setMax, name, title, height, width, remove, resize, force, mount }) => {
    let hadnleGridWidth = 420;
    if (name === 'DevicesList') hadnleGridWidth = 480;
    if (name === 'DeviceDetail') hadnleGridWidth = 570;
    console.log(mount);

    return (
        <div className='gridHandle d-flex justify-conten-evenly'>
            <span className={`ms-2 gridHandleOn ${lock || max ? '' : 'cursorgrap'}`}>{title}</span>
            <span className={`${width > hadnleGridWidth ? 'w-65' : ''} gridHandleOn ${lock || max ? '' : 'cursorgrap'}`}>  </span>
            <span className="m-auto">  </span>
            <div>
                <CloseBtn lock={lock} max={max} name={name} height={height} remove={remove} force={force} />
                {setMax ? <MaxBtn val={max} set={setMax} resize={resize} name={name} mount={mount} /> : ''}
            </div>
        </div>
    )
}