// import { parseISO, format } from 'date-fns'
// import { useState, useEffect } from "react";

// import { twoLine, headerTwoLine } from '../RidesView/ridesColumns';
// import { IoIosArrowDropdown, IoIosArrowDropup } from 'react-icons/io'
// import { FiSettings } from 'react-icons/fi';
// import Modal from 'react-bootstrap/Modal';

import { OverlayTrigger, Tooltip } from "react-bootstrap"
import i18n from '../../dictionary/i18n';
import { markStar } from '../../images/drivermark';
import { FaArrowTrendUp, FaArrowTrendDown } from "react-icons/fa6";
import { CarsGroup } from "../../images";
import { PiUsers, PiEngine } from "react-icons/pi";
import { LiaGasPumpSolid } from "react-icons/lia";

export const getCallRender = (name => {
    const twoLineItem = ['rpmIdle', 'load', 'fuelZero', 'dbrake', 'dBrakeM', 'cruiser', 'speedkm', 'rpmExce', 'continuity', 'predict', 'cbrake'];
    const greenVehicle = ['overspeed', 'acceleration', 'breaking', 'cornering', 'smooth', 'anticipation'];
    const lineMissing = ['fuelb', 'fuelp', 'fuelTotal', 'norm_fuel', 'average_fuel_long', 'gasb', 'gasp', 'gasTotal', 'phmPay', 'phmPriceOfLiter', 'tank', 'lasttank', 'priceOfKm', 'priceOfKmP', 'priceKmP'];
    if (name === 'rating') return Rating;
    else if (twoLineItem.includes(name)) return lineRating;
    else if (name === 'stamps') return twoLine;
    else if (name === 'average_fuel') return valueWitTrends;
    else if (name === 'name' || name === 'plate') return arrDevices;
    else if (name === 'drivers') return arrDrivers;
    else if (name === 'gasVsFuel') return fuelRating;
    else if (greenVehicle.includes(name)) return greenVehicleRating
    else if (lineMissing.includes(name)) return lineTitleMissing
})

export const getSort = (name => {
    const distance = ['distance', 'maxSpeed', 'alevationUp', 'distanceTotal', 'distanceb', 'distancep','tacho','lasttacho','diftacho'];
    const rating = ['rpmIdle', 'load', 'fuelZero', 'dbrake', 'dBrakeM', 'cruiser', 'speedkm', 'rpmExce', 'predict'];
    const fuel = ['fuelTotal', 'fuelIdle', 'fuel', 'fuelb', 'fuelp', 'gasb', 'gasp', 'gasTotal', 'gasVsFuel'];
    if (distance.includes(name)) return sortDistance;
    else if (fuel.includes(name)) return sortFuel;
    else if (name === 'average_fuel' || name === 'average_fuel_long' || name === 'norm_fuel') return sortFuelAvrg;
    else if (name === 'engine' || name === 'dride') return sortDuration;
    else if (rating.includes(name)) return sortPercent;
    else if (name === 'cbrake' || name === 'cbrake' || name === 'startUps') return sortCount;
    else if (name === 'rpmAvr') return sortRpm;
    else if (name === 'rating') return sortRating;
    else if (name === 'day') return sortServer
})

export const getLabel = ((name, devFocus) => {
    if (devFocus) {
        if (name.includes('tmp') && !name.includes('hmd')) {
            if (name === 'tmp0') return i18n.t('devices.tmp0');
            else {
                if (devFocus.sensors[name]) return i18n.t('devices.temperature') + ' ' + name.slice(3) + ' - ' + devFocus.sensors[name];
                else return i18n.t('devices.temperature') + ' ' + name.slice(3)
            }
        } else if (name.includes('hmd')) {
            if (devFocus.sensors[name.slice(0, -3)]) return i18n.t('devices.humidity') + ' ' + name.slice(3).slice(0, -3) + ' - ' + devFocus.sensors[name.slice(0, -3)];
            else return i18n.t('devices.humidity') + ' ' + name.slice(3).slice(0, -3)
        } else
            return i18n.t('devices.' + name);
    }
    else return i18n.t('stats.' + name);
})

export const Rating = ({ value, data }) => {
    if (value) {
        //if value are undefined  are object from search function
        if (value.length !== undefined) {
            const [text1, text2] = value.split(';');
            return (
                <div className='rgt-cell-inner rgt-cell-component' title={text1}>
                    <div className='rgt-text-truncate'>
                        {text1}
                    </div>
                    <div className='rgt-text-truncate'>
                        {markStar(text2)}
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className='rgt-cell-inner rgt-cell-component'>
                    <div className='rgt-text-truncate'>
                        {data.text.split(';')[0]}
                    </div>
                    <div className='rgt-text-truncate'>
                        {data.text.split(';')[1]}
                    </div>
                </div>
            )
        }
    }
}

export const lineRating = ({ value, data }) => {
    if (value) {
        const [percent, absValue, rating] = value.split(';');
        return (
            <div className={`rgt-cell-inner rgt-cell-component ${rating <= 3 ? 'rating-danger' : ''} ${rating > 3 && rating < 7 ? 'rating-warning' : ''} ${rating >= 7 ? 'rating-success' : ''}`} title={percent !== 'undefined' ? absValue + '\n' + (rating ? rating : '') : i18n.t('stats.dataMising')}>
                <div className='rgt-text-truncate'>
                    {percent !== 'undefined' ? percent : '-'}
                </div>
            </div>
        )
    }
}

export const twoLine = ({ value, data }) => {
    if (value) {
        const [line1, line2] = value.split(';');
        return (
            <div className={`rgt-cell-inner rgt-cell-component`}>
                <div className='rgt-text-truncate'>
                    {line1}
                </div>
                <div className='rgt-text-truncate'>
                    {line2}
                </div>
            </div>
        )
    }
}


export const arrDevices = ({ value, data, tableManager, column }) => {
    if (value) {
        if (value.length !== undefined) {
            const data = value.split(',');
            if (data.length < 2) {
                return (
                    <div className={`rgt-cell-inner rgt-cell-component`}>
                        <div className='rgt-text-truncate'>
                            {value}
                        </div>
                    </div>
                )
            }
            else {
                const widthColumns = parseInt(tableManager.columnsApi.columns.find(c => c.field === column.field).width);
                const context = document.createElement("canvas").getContext('2d');
                context.font = '0.9rem sans-serif';
                const width = context.measureText(`${data.join(', ')}`).width * 1.1;
                const groupDevices = widthColumns < width || width > 250;
                return (
                    <div className={`rgt-cell-inner rgt-cell-component`}>
                        <div className='rgt-text-truncate cursorpointer'>
                            {groupDevices ? <OverlayTrigger
                                placement="right"
                                delay={{ show: 400, hide: 400 }}
                                overlay={(props) => (
                                    <Tooltip {...props}>
                                        {data.map(d => { return <div>{d}</div> })}
                                    </Tooltip>
                                )}
                            >
                                <div>
                                    <span><CarsGroup height={20} /></span>
                                    <span className='ms-2'>{data.length}x</span>
                                </div>
                            </OverlayTrigger> : data.join(', ')}
                        </div>
                    </div>
                )
            }
        }
    }
}

export const arrDrivers = ({ value, data, tableManager, column }) => {
    if (value) {
        if (value.length !== undefined) {
            const data = value.split(',');
            if (data.length < 2) {
                return (
                    <div className={`rgt-cell-inner rgt-cell-component`}>
                        <div className='rgt-text-truncate'>
                            {value}
                        </div>
                    </div>
                )
            }
            else {
                const widthColumns = parseInt(tableManager.columnsApi.columns.find(c => c.field === column.field).width);
                const context = document.createElement("canvas").getContext('2d');
                context.font = '0.9rem sans-serif';
                const width = context.measureText(`${data.join(', ')}`).width * 1.1;
                const groupDrivers = widthColumns < width || width > 250
                return (
                    <div className={`rgt-cell-inner rgt-cell-component`}>
                        <div className='rgt-text-truncate cursorpointer'>
                            {groupDrivers ? <OverlayTrigger
                                placement="right"
                                delay={{ show: 400, hide: 400 }}
                                overlay={(props) => (
                                    <Tooltip {...props}>
                                        {data.map(d => { return <div>{d}</div> })}
                                    </Tooltip>
                                )}
                            >
                                <div>
                                    <span><PiUsers className='fs-5 mb-1' /></span>
                                    <span className='ms-2'>{data.length}x</span>
                                </div>
                            </OverlayTrigger> : data.join(', ')}
                        </div>
                    </div>
                )
            }
        }
    }
}

export const fuelRating = ({ value, data }) => {
    if (value) {
        const aValue = value.split(';')
        return (
            <div className={`rgt-cell-inner rgt-cell-component ${aValue[1] > 0.15 ? (aValue[1] < 0.25 ? 'rating-warning' : 'rating-danger') : ''}`} title={aValue[0] !== '-' ? aValue[0] : i18n.t('stats.valueMissing')}>
                <div className='rgt-text-truncate'>
                    {aValue[0]}
                </div>
            </div>
        )
    }
}

export const valueWitTrends = ({ value, data }) => {
    if (value) {
        const aValue = value.split(';');
        let icon = <></>;
        if (aValue[1] !== 'null') {
            if (aValue[1] === 'up') icon = <FaArrowTrendUp className='me-2 text-danger' />
            if (aValue[1] === 'down') icon = <FaArrowTrendDown className='me-2 text-success' />
        }
        return (
            <div className={`rgt-cell-inner rgt-cell-component ${aValue[2] > 0.15 ? (aValue[2] < 0.25 ? 'rating-warning' : 'rating-danger') : ''}`} title={aValue[0] !== '-' ? aValue[0] : i18n.t('stats.valueMissing')}>
                <div className='rgt-text-truncate'>
                    {icon}
                    {aValue[0]}
                </div>
            </div>
        )
    }
}

export const greenVehicleRating = ({ value, data }) => {
    if (value) {
        const getNumber = (n) => {
            return parseFloat(n.replace(',', '.'))
        }
        const minValue = (Math.min(getNumber(data.acceleration), getNumber(data.anticipation), getNumber(data.breaking), getNumber(data.cornering), getNumber(data.overspeed), getNumber(data.smooth)));
        const maxValue = (Math.max(getNumber(data.acceleration), getNumber(data.anticipation), getNumber(data.breaking), getNumber(data.cornering), getNumber(data.overspeed), getNumber(data.smooth)));
        return (
            <div className={`rgt-cell-inner rgt-cell-component ${minValue === getNumber(value) ? 'rating-danger' : ''} ${maxValue === getNumber(value) ? 'rating-success' : ''}`} title={value}>
                <div className='rgt-text-truncate'>
                    {value}
                </div>
            </div>
        )
    }
}

export const lineTitleMissing = ({ value, data }) => {
    if (value) {
        const aValue = value.split(';');
        return (
            <div className={`rgt-cell-inner rgt-cell-component`} title={aValue[0] === '-' ? i18n.t('stats.valueMissing') : (aValue[0] + '\n' + (aValue[1] ? i18n.t('stats.sourceFuel_' + aValue[1]) : ''))}>
                <div className='rgt-text-truncate'>
                    {aValue[1] ? aValue[1] === 'can' ? < PiEngine className='me-1 mb-1' /> : <LiaGasPumpSolid className='me-1 mb-1' /> : ''}
                    {aValue[0]}
                </div>
            </div>
        )
    }
}

export const headerWithTitle = ({ column }) => {
    return <span className="rgt-text-truncate" data-column-id={column.id}
        title={i18n.exists('stats.' + column.field + '_title') ? i18n.t('stats.' + column.field + '_title') : column.label}>
        {column.label}
    </span>
}

export const sortDistance = ({ a, b, isAscending }) => {
    let aa = parseFloat(a.replace(/\s+|km/g, '').replace(',', '.'))
    let bb = parseFloat(b.replace(/\s+|km/g, '').replace(',', '.'))
    aa = aa ? aa : 0;
    bb = bb ? bb : 0;
    return aa < bb ? isAscending ? -1 : 1 : (aa > bb ? isAscending ? 1 : -1 : 0);
}

export const sortFuel = ({ a, b, isAscending }) => {
    let aa = parseFloat(a.replace(/\s+|l/g, '').replace(',', '.'))
    let bb = parseFloat(b.replace(/\s+|l/g, '').replace(',', '.'))
    aa = aa ? aa : 0;
    bb = bb ? bb : 0;
    return aa < bb ? isAscending ? -1 : 1 : (aa > bb ? isAscending ? 1 : -1 : 0);
}

export const sortFuelAvrg = ({ a, b, isAscending }) => {
    let aa = parseFloat(a.replace(/\s*l\/100\s*km\s*/g, '').replace(',', '.'))
    let bb = parseFloat(b.replace(/\s*l\/100\s*km\s*/g, '').replace(',', '.'))
    aa = aa ? aa : 0;
    bb = bb ? bb : 0;
    return aa < bb ? isAscending ? -1 : 1 : (aa > bb ? isAscending ? 1 : -1 : 0);
}

export const sortCount = ({ a, b, isAscending }) => {
    let aa = parseInt(a.replace(/x/g, ''))
    let bb = parseInt(b.replace(/x/g, ''))
    aa = aa ? aa : 0;
    bb = bb ? bb : 0;
    return aa < bb ? isAscending ? -1 : 1 : (aa > bb ? isAscending ? 1 : -1 : 0);
}

export const sortDuration = ({ a, b, isAscending }) => {
    let aa = (a.split(':')[0]) * 60
    let bb = (b.split(':')[0]) * 60
    aa = aa ? aa : 0;
    bb = bb ? bb : 0;
    return aa < bb ? isAscending ? -1 : 1 : (aa > bb ? isAscending ? 1 : -1 : 0);
}


export const sortPercent = ({ a, b, isAscending }) => {
    let aa = parseFloat(a.replace(/\s+|%/g, '').replace(',', '.'))
    let bb = parseFloat(b.replace(/\s+|%/g, '').replace(',', '.'))
    aa = aa ? aa : 0;
    bb = bb ? bb : 0;
    return aa < bb ? isAscending ? -1 : 1 : (aa > bb ? isAscending ? 1 : -1 : 0);
}

export const sortRpm = ({ a, b, isAscending }) => {
    let aa = parseFloat(a.replace(/\s+|rpm/g, '').replace(',', '.'))
    let bb = parseFloat(b.replace(/\s+|rpm/g, '').replace(',', '.'))
    aa = aa ? aa : 0;
    bb = bb ? bb : 0;
    return aa < bb ? isAscending ? -1 : 1 : (aa > bb ? isAscending ? 1 : -1 : 0);
}

export const sortRating = ({ a, b, isAscending }) => {
    let aa = parseFloat(a.replace(',', '.'));
    let bb = parseFloat(b.replace(',', '.'));
    return aa < bb ? isAscending ? -1 : 1 : (aa > bb ? isAscending ? 1 : -1 : 0);
}

export const sortServer = () => {

}


export const columnsDriverCheck = [
    {
        id: 1,
        index: 0,
        field: 'driver',
        width: 'auto',
        visible: true,
        searchable: true,
        headerCellRenderer: headerWithTitle,
        pinned: true,
    },
    {
        id: 2,
        index: 1,
        field: 'name',
        width: 'auto',
        visible: false,
        headerCellRenderer: headerWithTitle,
        searchable: true,
        cellRenderer: arrDevices,
    },
    {
        id: 3,
        index: 2,
        field: 'plate',
        visible: false,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: true,
        cellRenderer: arrDevices,
    },
    {
        id: 4,
        index: 3,
        field: 'stamps',
        visible: false,
        cellRenderer: twoLine,
        headerCellRenderer: headerWithTitle,
        width: 'auto',
        searchable: false,
        sortable: false
    },
    {
        id: 5,
        index: 4,
        field: 'distance',
        visible: true,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortDistance,
    },
    {
        id: 6,
        index: 5,
        field: 'rating',
        cellRenderer: Rating,
        visible: true,
        headerCellRenderer: headerWithTitle,
        width: 'auto',
        searchable: false,
        sort: sortRating,
    },
    {
        id: 7,
        index: 6,
        field: 'fuelTotal',
        visible: false,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortFuel,
    },
    {
        id: 8,
        index: 7,
        field: 'fuelIdle',
        visible: false,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortFuel,
    },
    {
        id: 9,
        index: 8,
        field: 'average_fuel',
        visible: true,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortFuelAvrg
    },
    {
        id: 10,
        index: 9,
        field: 'engine',
        visible: false,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortDuration
    },
    {
        id: 11,
        index: 10,
        field: 'rpmIdle',
        cellRenderer: lineRating,
        visible: true,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortPercent
    },
    {
        id: 12,
        index: 11,
        field: 'dride',
        visible: false,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortDuration
    },
    {
        id: 13,
        index: 12,
        field: 'load',
        cellRenderer: lineRating,
        visible: false,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortPercent
    },
    {
        id: 14,
        index: 13,
        field: 'fuelZero',
        cellRenderer: lineRating,
        visible: true,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortPercent
    },
    {
        id: 15,
        index: 14,
        field: 'cbrake',
        cellRenderer: lineRating,
        visible: true,
        headerCellRenderer: headerWithTitle,
        width: 'auto',
        searchable: false,
        soert: sortCount
    },
    {
        id: 16,
        index: 15,
        field: 'dbrake',
        cellRenderer: lineRating,
        visible: true,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortPercent
    },
    {
        id: 17,
        index: 16,
        field: 'dBrakeM',
        cellRenderer: lineRating,
        visible: true,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortPercent
    },
    {
        id: 18,
        index: 17,
        field: 'cruiser',
        cellRenderer: lineRating,
        visible: true,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortPercent
    },
    {
        id: 19,
        index: 18,
        field: 'speedkm',
        cellRenderer: lineRating,
        visible: true,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortPercent
    },
    {
        id: 20,
        index: 19,
        field: 'rpmExce',
        cellRenderer: lineRating,
        visible: true,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortPercent
    },
    {
        id: 21,
        index: 20,
        field: 'predict',
        cellRenderer: lineRating,
        visible: true,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortPercent
    },
    {
        id: 22,
        index: 21,
        field: 'weight',
        visible: true,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
    },
    {
        id: 23,
        index: 22,
        field: 'rpmAvr',
        visible: false,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortRpm
    },
    {
        id: 24,
        index: 23,
        field: 'maxSpeed',
        visible: false,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortDistance
    },
    {
        id: 25,
        index: 24,
        field: 'alevationUp',
        visible: true,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortDistance
    },
    {
        id: 26,
        index: 25,
        field: 'startUps',
        visible: true,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortCount
    },

]

export const columnsDriverCheck2 = [
    {
        id: 1,
        index: 0,
        field: 'driver',
        width: 'auto',
        visible: true,
        searchable: true,
        headerCellRenderer: headerWithTitle,
        pinned: true,
    },
    {
        id: 2,
        index: 1,
        field: 'name',
        width: 'auto',
        visible: false,
        headerCellRenderer: headerWithTitle,
        searchable: false,
        cellRenderer: arrDevices,
    },
    {
        id: 3,
        index: 2,
        field: 'plate',
        visible: true,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        cellRenderer: arrDevices,
    },
    {
        id: 4,
        index: 3,
        field: 'stamps',
        visible: false,
        cellRenderer: twoLine,
        headerCellRenderer: headerWithTitle,
        width: 'auto',
        searchable: false,
        sortable: false
    },
    {
        id: 5,
        index: 4,
        field: 'distance',
        visible: true,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortDistance,
    },
    {
        id: 6,
        index: 5,
        field: 'rating',
        visible: true,
        cellRenderer: Rating,
        headerCellRenderer: headerWithTitle,
        width: 'auto',
        searchable: false,
        sort: sortRating,
    },
    {
        id: 7,
        index: 6,
        field: 'fuelTotal',
        visible: false,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortFuel,
    },
    {
        id: 8,
        index: 7,
        field: 'average_fuel',
        visible: true,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortFuelAvrg
    },
    {
        id: 9,
        index: 8,
        field: 'engine',
        visible: false,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortDuration
    },
    {
        id: 10,
        index: 9,
        field: 'dride',
        visible: false,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortDuration
    },
    {
        id: 11,
        index: 10,
        field: 'overspeed',
        visible: true,
        width: 'auto',
        searchable: false,
        cell: greenVehicleRating
    },
    {
        id: 12,
        index: 11,
        field: 'acceleration',
        visible: true,
        width: 'auto',
        searchable: false,
        cell: greenVehicleRating
    },
    {
        id: 13,
        index: 12,
        field: 'breaking',
        visible: true,
        width: 'auto',
        searchable: false,
        cell: greenVehicleRating
    },
    {
        id: 14,
        index: 13,
        field: 'cornering',
        visible: true,
        width: 'auto',
        searchable: false,
        cell: greenVehicleRating
    },
    {
        id: 15,
        index: 14,
        field: 'smooth',
        visible: true,
        width: 'auto',
        searchable: false,
        cell: greenVehicleRating
    },
    {
        id: 16,
        index: 15,
        field: 'anticipation',
        visible: true,
        width: 'auto',
        searchable: false,
        cell: greenVehicleRating
    },
    {
        id: 17,
        index: 16,
        field: 'rpmAvr',
        visible: true,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortRpm
    },
    {
        id: 18,
        index: 17,
        field: 'maxSpeed',
        visible: true,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortDistance
    },
    {
        id: 19,
        index: 18,
        field: 'alevationUp',
        visible: false,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortDistance
    },
    {
        id: 20,
        index: 19,
        field: 'startUps',
        visible: false,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortCount
    },
    {
        id: 21,
        index: 20,
        field: 'cityr',
        visible: true,
        width: 'auto',
        searchable: false,
    },
    {
        id: 22,
        index: 21,
        field: 'ocityr',
        visible: true,
        width: 'auto',
        searchable: false,
    },
    {
        id: 23,
        index: 22,
        field: 'ocityh',
        visible: true,
        width: 'auto',
        searchable: false,
    },
]

export const columnsSensorsReport = [
    {
        id: 1,
        index: 0,
        field: 'stamp',
        width: 'auto',
        visible: true,
        searchable: false,
    },
    {
        id: 2,
        index: 1,
        field: 'ucode',
        width: 'auto',
        visible: true,
        searchable: false,
    },
    {
        id: 3,
        index: 2,
        field: 'position',
        width: 'auto',
        visible: true,
        searchable: true,
    },
    {
        id: 4,
        index: 3,
        field: 'gps',
        width: 'auto',
        visible: false,
        searchable: false,
    },
    {
        id: 5,
        index: 4,
        field: 'speed',
        width: 'auto',
        visible: true,
        searchable: false,
    },
    {
        id: 6,
        index: 5,
        field: 'pwr1',
        width: 'auto',
        visible: true,
        searchable: false,
    },
    {
        id: 7,
        index: 6,
        field: 'pwr2',
        width: 'auto',
        visible: true,
        searchable: false,
    },
    {
        id: 8,
        index: 7,
        field: 'bat',
        width: 'auto',
        visible: true,
        searchable: false,
    },
    {
        id: 9,
        index: 8,
        field: 'tmp0',
        width: 'auto',
        visible: true,
        searchable: false,
    },
    {
        id: 10,
        index: 9,
        field: 'tmp1',
        width: 'auto',
        visible: true,
        searchable: false,
    },
    {
        id: 11,
        index: 10,
        field: 'tmp2',
        width: 'auto',
        visible: true,
        searchable: false,
    },
    {
        id: 12,
        index: 11,
        field: 'tmp3',
        width: 'auto',
        visible: true,
        searchable: false,
    },
    {
        id: 13,
        index: 12,
        field: 'tmp4',
        width: 'auto',
        visible: true,
        searchable: false,
    },
    {
        id: 14,
        index: 13,
        field: 'tmp5',
        width: 'auto',
        visible: true,
        searchable: false,
    },
    {
        id: 15,
        index: 14,
        field: 'tmp5hmd',
        width: 'auto',
        visible: true,
        searchable: false,
    },
    {
        id: 16,
        index: 15,
        field: 'tmp6',
        width: 'auto',
        visible: true,
        searchable: false,
    },
    {
        id: 17,
        index: 16,
        field: 'tmp6hmd',
        width: 'auto',
        visible: true,
        searchable: false,
    },
    {
        id: 18,
        index: 17,
        field: 'tmp7',
        width: 'auto',
        visible: true,
        searchable: false,
    },
    {
        id: 19,
        index: 18,
        field: 'tmp7hmd',
        width: 'auto',
        visible: true,
        searchable: false,
    },
    {
        id: 20,
        index: 19,
        field: 'tmp8',
        width: 'auto',
        visible: true,
        searchable: false,
    },
    {
        id: 21,
        index: 20,
        field: 'tmp8hmd',
        width: 'auto',
        visible: true,
        searchable: false,
    },
    {
        id: 22,
        index: 21,
        field: 'accelerationval',
        width: 'auto',
        visible: true,
        searchable: false,
    },
    {
        id: 23,
        index: 22,
        field: 'alt',
        width: 'auto',
        visible: false,
        searchable: false,
    },
    {
        id: 24,
        index: 23,
        field: 'rpm',
        width: 'auto',
        visible: true,
        searchable: false,
    },
    {
        id: 25,
        index: 24,
        field: 'tacho',
        width: 'auto',
        visible: true,
        searchable: false,
    },
    {
        id: 26,
        index: 25,
        field: 'tankl',
        width: 'auto',
        visible: true,
        searchable: false,
    },
    {
        id: 27,
        index: 26,
        field: 'signal',
        width: 'auto',
        visible: false,
        searchable: false,
    },
    {
        id: 28,
        index: 27,
        field: 'pdop',
        width: 'auto',
        visible: false,
        searchable: false,
    },
    {
        id: 29,
        index: 28,
        field: 'network',
        width: 'auto',
        visible: false,
        searchable: false,
    },
    {
        id: 30,
        index: 29,
        field: 'odo',
        width: 'auto',
        visible: true,
        searchable: false,
    },
    {
        id: 31,
        index: 30,
        field: 'fuel',
        width: 'auto',
        visible: true,
        searchable: false,
    },
    {
        id: 32,
        index: 31,
        field: 'din1',
        width: 'auto',
        visible: true,
        searchable: false,
    },
    {
        id: 33,
        index: 32,
        field: 'din2',
        width: 'auto',
        visible: true,
        searchable: false,
    },

]

export const columnsStatsVehicles = [
    {
        id: 1,
        index: 0,
        field: 'nameS',
        width: 'auto',
        visible: true,
        searchable: true,
        headerCellRenderer: headerWithTitle,
        pinned: true,
    },
    {
        id: 2,
        index: 1,
        field: 'plateS',
        visible: true,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: true,
    },
    {
        id: 3,
        index: 2,
        field: 'drivers',
        visible: false,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        cellRenderer: arrDrivers,
    },
    {
        id: 4,
        index: 3,
        field: 'stamps',
        visible: false,
        cellRenderer: lineRating,
        headerCellRenderer: headerWithTitle,
        width: 'auto',
        searchable: false,
        sortable: false
    },
    {
        id: 12,
        index: 4,
        field: 'durb',
        visible: false,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
    },
    {
        id: 13,
        index: 5,
        field: 'durp',
        visible: false,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
    },
    {
        id: 5,
        index: 6,
        field: 'durTotal',
        visible: true,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
    },
    {
        id: 30,
        index: 7,
        field: 'tacho',
        visible: false,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortDistance
    },
    {
        id: 31,
        index: 8,
        field: 'lasttacho',
        visible: false,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortDistance
    },
    {
        id: 10,
        index: 9,
        field: 'distanceb',
        visible: false,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortDistance
    },
    {
        id: 11,
        index: 10,
        field: 'distancep',
        visible: false,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortDistance
    },
    {
        id: 6,
        index: 11,
        field: 'distanceTotal',
        visible: true,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortDistance
    },
    {
        id: 32,
        index: 12,
        field: 'tank',
        visible: false,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        cell: lineTitleMissing
    },
    {
        id: 33,
        index: 13,
        field: 'lasttank',
        visible: false,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        cell: lineTitleMissing
    },
    {
        id: 16,
        index: 14,
        field: 'fuelb',
        visible: false,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortFuel,
        cell: lineTitleMissing
    },
    {
        id: 17,
        index: 15,
        field: 'fuelp',
        visible: false,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortFuel,
        cell: lineTitleMissing
    },
    {
        id: 7,
        index: 16,
        field: 'fuelTotal',
        visible: true,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortFuel,
        cell: lineTitleMissing
    },
    {
        id: 18,
        index: 17,
        field: 'gasb',
        visible: false,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortFuel,
        cell: lineTitleMissing
    },
    {
        id: 19,
        index: 18,
        field: 'gasp',
        visible: false,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortFuel,
        cell: lineTitleMissing
    },
    {
        id: 20,
        index: 19,
        field: 'gasTotal',
        visible: true,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortFuel,
        cell: lineTitleMissing
    },
    {
        id: 34,
        index: 20,
        field: 'gasVsFuel',
        visible: true,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortFuel,
        cell: fuelRating
    },
    {
        id: 21,
        index: 21,
        field: 'phmPay',
        visible: true,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        cell: lineTitleMissing
    },
    {
        id: 22,
        index: 22,
        field: 'phmPriceOfLiter',
        visible: true,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        cell: lineTitleMissing
    },
    {
        id: 8,
        index: 23,
        field: 'average_fuel',
        visible: true,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        cellRenderer: valueWitTrends,
        searchable: false,
        sort: sortFuelAvrg
    },
    {
        id: 9,
        index: 24,
        field: 'average_fuel_long',
        visible: true,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortFuelAvrg,
        cell: lineTitleMissing
    },
    {
        id: 26,
        index: 25,
        field: 'norm_fuel',
        visible: false,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortFuelAvrg,
        cell: lineTitleMissing
    },
    {
        id: 23,
        index: 26,
        field: 'priceOfKm',
        visible: false,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        cell: lineTitleMissing
    },
    {
        id: 24,
        index: 27,
        field: 'priceOfKmP',
        visible: false,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        cell: lineTitleMissing
    },
    {
        id: 25,
        index: 28,
        field: 'priceKmP',
        visible: false,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        cell: lineTitleMissing
    },
    {
        id: 27,
        index: 29,
        field: 'cls',
        visible: false,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
    },
    {
        id: 28,
        index: 30,
        field: 'devDriver',
        visible: false,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
    },
    {
        id: 29,
        index: 31,
        field: 'driverRid',
        visible: false,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
    },
    {
        id: 35,
        index: 32,
        field: 'diftacho',
        visible: false,
        width: 'auto',
        headerCellRenderer: headerWithTitle,
        searchable: false,
        sort: sortDistance
    },
]

export const columnsCRoCsv = [
    {
        id: 1,
        index: 0,
        field: 'driverRid',
        visible: true,
    },
    {
        id: 2,
        index: 1,
        field: 'devDriver',
        visible: true,
    },
    {
        id: 3,
        index: 2,
        field: 'cls',
        visible: true,
    },
    {
        id: 4,
        index: 3,
        field: 'priceKmP',
        visible: true,
    },
]

export const columnsCRoPdf = [
    {
        id: 1,
        index: 0,
        field: 'driverRid',
        visible: true,
    },
    {
        id: 2,
        index: 1,
        field: 'devDriver',
        visible: true,
    },
    {
        id: 3,
        index: 2,
        field: 'cls',
        visible: true,
    },
    {
        id: 4,
        index: 3,
        field: 'priceKmP',
        visible: true,
    },
    {
        id: 5,
        index: 4,
        field: 'plateS',
        visible: true,
    },
    {
        id: 6,
        index: 5,
        field: 'distancep',
        visible: true,
    },
    {
        id: 7,
        index: 6,
        field: 'average_fuel',
        visible: true,
    },
    {
        id: 8,
        index: 7,
        field: 'phmPriceOfLiter',
        visible: true,
    },
]

export const columnsStatsSelection = [
    {
        id: 1,
        index: 0,
        field: 'name',
        width: 'auto',
        visible: true,
        searchable: false,
        cellRenderer: arrDevices,
    },
    {
        id: 2,
        index: 1,
        field: 'plate',
        width: 'auto',
        visible: true,
        searchable: false,
        cellRenderer: arrDevices,
    },
    {
        id: 3,
        index: 2,
        field: 'drivers',
        width: 'auto',
        visible: true,
        searchable: false,
        cellRenderer: arrDrivers,
    },
    {
        id: 4,
        index: 3,
        field: 'driver2',
        width: 'auto',
        visible: false,
        searchable: false,
        cellRenderer: arrDrivers,
    },
    {
        id: 5,
        index: 4,
        field: 'purpose',
        width: 'auto',
        visible: true,
        searchable: true,
    },
    {
        id: 6,
        index: 5,
        field: 'day',
        width: 'auto',
        visible: true,
        searchable: false,
        sort: sortServer,
    },
    {
        id: 7,
        index: 6,
        field: 'start',
        width: 'auto',
        visible: true,
        searchable: false,
    },
    {
        id: 8,
        index: 7,
        field: 'end',
        width: 'auto',
        visible: true,
        searchable: false,
    },
    {
        id: 9,
        index: 8,
        field: 'durp',
        width: 'auto',
        visible: false,
        searchable: false,
    },
    {
        id: 10,
        index: 9,
        field: 'durb',
        width: 'auto',
        visible: false,
        searchable: false,
    },
    {
        id: 11,
        index: 10,
        field: 'durTotal',
        width: 'auto',
        visible: true,
        searchable: false,
    },
    {
        id: 12,
        index: 11,
        field: 'durationPark',
        width: 'auto',
        visible: false,
        searchable: false,
    },
    {
        id: 13,
        index: 12,
        field: 'durationTotal',
        width: 'auto',
        visible: false,
        searchable: false,
    },
    {
        id: 14,
        index: 13,
        field: 'distancep',
        width: 'auto',
        visible: false,
        searchable: false,
    },
    {
        id: 15,
        index: 14,
        field: 'distanceb',
        width: 'auto',
        visible: false,
        searchable: false,
    },
    {
        id: 16,
        index: 15,
        field: 'distanceTotal',
        width: 'auto',
        visible: true,
        searchable: false,
    },
    {
        id: 17,
        index: 16,
        field: 'maxSpeed',
        width: 'auto',
        visible: false,
        searchable: false,
    },
]