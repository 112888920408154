import React, { useState, useContext, useRef, useEffect, Suspense } from "react";
import { client } from 'websocket';
import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";
import { Button, Container, Dropdown, DropdownButton, Offcanvas } from "react-bootstrap"
import { LinkContainer } from 'react-router-bootstrap'
import SidebarMenu from 'react-bootstrap-sidebar-menu';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';

import package_json from '../../../package.json';
import { WsLogout, WsSend, } from "../../scripts/ws";
import { layoutOverviewDefault } from "../../pages/Overview/layoutOverview"
import { layoutRidesAll, layoutRidesDefault } from "../../pages/Rides/layoutRides"
import { layoutTachoAll, layoutTachoDefault } from "../../pages/Tacho/layoutTacho"
import { checkName } from "../../pages/gridLayout";
import { UserContext, isBrand, useWindowSize } from ".."
import { Logomin, Logo } from "../../images";
// import { SelectComponentClients } from "../SelectComponent/SelectComponent"
import { parseISO, format } from 'date-fns';
import { isClient } from "..";
import { menuIcon } from "../../pages/Menu/Menu";

import { BsBuildingsFill } from "react-icons/bs";
import { SlLogout, SlUser } from 'react-icons/sl';
import { BiAddToQueue } from 'react-icons/bi';
import { FaCar, FaBell } from 'react-icons/fa';
import { RiLayoutMasonryFill, RiArrowDropRightLine } from 'react-icons/ri';
import { RxLockClosed, RxLockOpen2, RxReload } from 'react-icons/rx'
import { IoStatsChartSharp, IoSettings } from 'react-icons/io5'
import { FaRoute } from 'react-icons/fa'
import { FaTachographDigital } from "react-icons/fa6";
import { IoWarningOutline, IoInformationCircleOutline } from "react-icons/io5";
import { BiWorld } from "react-icons/bi";
import { MdCarRental } from "react-icons/md";

import './NavBar.scss'

const SelectComponentClients = React.lazy(() => import('../SelectComponent/SelectComponent').then(module => ({ default: module.SelectComponentClients })));

const NavBar = ({ setLogin, setWidgetOnDrop, setDefaultLayout }) => {
  function logout() {
    WsLogout();
    setLogin(false);
  }

  const { t } = useTranslation('translation', { keyPrefix: 'widget' });
  const userDetails = useContext(UserContext);
  const widthWindow = useWindowSize().width;
  const sideBar = useContext(UserContext).uiConfig.sideBar === false ? false : true;
  const layoutOverview = useContext(UserContext).uiConfig.layoutOverview;
  const layoutRides = useContext(UserContext).uiConfig.layoutRides;
  const layoutTacho = useContext(UserContext).uiConfig.layoutTacho;
  const navbar = useContext(UserContext).uiConfig.navbar;
  const { uiConfig, setUiConfig, setDev, setDriv, costumer, setCostumer, clientRoles } = useContext(UserContext);
  const pathname = useLocation().pathname.split('/');
  const navigate = useNavigate()
  const routerToFP = useRef(useLocation().pathname === '/' ? false : true);
  const [notification, setNotification] = useState(false);

  useEffect(() => {
    if (!routerToFP.current) {
      if (clientRoles.some(i => i.type.symbol === 'view_events') || clientRoles.some(i => i.type.symbol === 'data_curator')) {
        routerToFP.current = true;
        navigate('/overview');
      }
      if (!routerToFP.current && (clientRoles.some(i => i.type.symbol === 'view_history') || clientRoles.some(i => i.type.symbol === 'data_curator'))) {
        routerToFP.current = true;
        navigate('/rides')
      }
      if (!routerToFP.current && (clientRoles.some(i => i.type.symbol === 'view_report') || clientRoles.some(i => i.type.symbol === 'data_curator'))) {
        routerToFP.current = true;
        navigate('/reports')
      }
      if (!routerToFP.current && (clientRoles.some(i => i.type.symbol === 'edit_maintenances') || clientRoles.some(i => i.type.symbol === 'data_curator'))) {
        routerToFP.current = true;
        navigate('/tacho')
      }
      if (!routerToFP.current && isClient('ceskyRozhlas', costumer)) {
        routerToFP.current = true;
        navigate('/autopujcovna')
      }
    }
  }, [])

  const checkRoleForSearch = (r) => {
    let res = false
    r.forEach(v => {
      if (v === 'iscurator' || v === 'dealer' || v === 'resellers') res = true;
    })
    return res
  }

  const checkMount = (findItem, layouts) => {
    const res = layouts.lg.find(i => {
      return i.i === findItem
    })
    if (res) return true
    else return false
  }

  const chooseLayout = ((page) => {
    if (page === "/overview") {
      return (layoutOverviewDefault.lg.map((i) => {
        const l = layoutOverview ? layoutOverview : layoutOverviewDefault;
        return ({ val: i.i, mounted: checkMount(i.i, l), title: checkName(i.i), storage: "layoutOverview" })
      }))
    }
    else if (page === "/rides") {
      return (layoutRidesAll.map((i) => {
        const l = layoutRides ? layoutRides : layoutRidesDefault;
        return ({ val: i.i, mounted: checkMount(i.i, l), title: checkName(i.i), storage: "layoutRides" })
      }))
    }
    else if (page === "/tacho") {
      return (layoutTachoAll.map((i) => {
        const l = layoutTacho ? layoutTacho : layoutTachoDefault;
        return ({ val: i.i, mounted: checkMount(i.i, l), title: checkName(i.i), storage: "layoutTacho" })
      }))
    }
    else return null
  })

  const layoutWidget = chooseLayout(useLocation().pathname);
  const page = useLocation().pathname;

  const leyoutWidgetAll = (() => {
    if (!layoutWidget) return true
    let res = true;
    layoutWidget.forEach((item) => {
      if (!item.mounted) res = false;
    })
    return res
  })

  const LayoutWidget = () => {
    if (!layoutWidget) return (<></>)
    return layoutWidget.map((item) => {
      if (!item.mounted) {
        return (<Dropdown.Item key={item.val} onClick={() => { setWidgetOnDrop(item.val) }}> <BiAddToQueue style={{ width: '40px' }} />{item.title}</Dropdown.Item>)
      }
    })
  }

  return (
    <>
      <SidebarMenu bg="light" variant="light" expand="sm" expanded={sideBar} activeKey={"default_null"} >
        <Logomin className="logomin" title={"Powered by LOGISCARE  " + package_json.version} style={{ position: 'absolute', marginLeft: '20px', marginTop: '10px', display: widthWindow < 577 || !sideBar ? 'block' : 'none', transform: 'scale(1.5)' }} />
        <SidebarMenu.Collapse style={{ paddingTop: widthWindow < 577 || !sideBar ? '45px' : '' }} onKeyDown={(e => e.stopPropagation())}>
          <SidebarMenu.Header>
            <SidebarMenu.Brand>
              <Container><Suspense><Logo className="sidebar-logo-header" title={"Powered by LOGISCARE  " + package_json.version} /></Suspense>
              </Container>
            </SidebarMenu.Brand>
            {/* <SidebarMenu.Toggle onClick={() => { setSidebar(!userDetails.sidebar); localStorage.setItem('sidebar', !userDetails.sidebar) }} /> */}
            <SidebarMenu.Toggle onClick={() => {
              setUiConfig({ ...uiConfig, sideBar: !sideBar })
            }} />
          </SidebarMenu.Header>
          {checkRoleForSearch(userDetails.clientGroups) ? (
            <Suspense>
              <SelectComponentClients
                client={userDetails.costumer}
                WsSend={WsSend}
                listClientSearch={userDetails.listClientSearch}
                setDev={setDev}
                setDriv={setDriv}
                setClient={setCostumer}
                sideBar={widthWindow > 577 && sideBar ? true : false}
              />
            </Suspense>) : null}
          <div className="sidebar-menu-maindiv">
            <SidebarMenu.Body>
              <SidebarMenu.Nav>
                {/* link with submenu */}
                {/* <SidebarMenu.Sub expanded={ost}>
                  <LinkContainer to="/overview" className="btn btn-light">
                    <SidebarMenu.Nav.Link eventKey="over" className="btn btn-light d-flex" style={{ alignItems: 'center' }}>
                      <SidebarMenu.Nav.Icon className='fs-4' ><FaCar /></SidebarMenu.Nav.Icon>
                      <SidebarMenu.Nav.Title>aaa</SidebarMenu.Nav.Title>
                      <SidebarMenu.Nav.Icon onClick={() => setOst(!ost)} className="float-end ms-4"  ><RiArrowDropRightLine style={ost ? { transform: 'rotate(90deg)', transition: 'transform 0.3s ease', marginBottom: '5px' } : { transform: 'rotate(0deg)', transition: 'transform 0.3 ease', marginBottom: '5px' }} /></SidebarMenu.Nav.Icon>
                    </SidebarMenu.Nav.Link>
                  </LinkContainer>
                  <SidebarMenu.Sub.Collapse>
                    <LinkContainer to="/map">
                      <SidebarMenu.Nav.Link eventKey="map" className="btn btn-light d-flex">
                        <SidebarMenu.Nav.Icon className='fs-4'><FaRoute /></SidebarMenu.Nav.Icon>
                        <SidebarMenu.Nav.Title>Mapa</SidebarMenu.Nav.Title>
                      </SidebarMenu.Nav.Link>
                    </LinkContainer>
                  </SidebarMenu.Sub.Collapse>
                </SidebarMenu.Sub> */}
                <LinkContainer to="/menu">
                  <SidebarMenu.Nav.Link eventKey="menu" className="btn btn-light d-flex">
                    <SidebarMenu.Nav.Icon className='fs-4'>{<BsBuildingsFill />}</SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>{t('menu')}</SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </LinkContainer>
                {/* {clientRoles.some(i => i.type.symbol === 'view_events') || clientRoles.some(i => i.type.symbol === 'data_curator') ?
                  <LinkContainer to="/overview">
                    <SidebarMenu.Nav.Link eventKey="overview" className="btn btn-light d-flex">
                      <SidebarMenu.Nav.Icon className='fs-4'>{isBrand('alfakomtemp') ? <BsBuildingsFill /> : <FaCar />}</SidebarMenu.Nav.Icon>
                      <SidebarMenu.Nav.Title>{t('overview')}</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Link>
                  </LinkContainer> : <></>}
                {clientRoles.some(i => i.type.symbol === 'view_history') || clientRoles.some(i => i.type.symbol === 'data_curator') ?
                  <LinkContainer to="/rides">
                    <SidebarMenu.Nav.Link eventKey="rides" className="btn btn-light d-flex">
                      <SidebarMenu.Nav.Icon className='fs-4'><FaRoute /></SidebarMenu.Nav.Icon>
                      <SidebarMenu.Nav.Title>{t('rides')}</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Link>
                  </LinkContainer>
                  : <></>}
                {clientRoles.some(i => i.type.symbol === 'view_history') || clientRoles.some(i => i.type.symbol === 'view_report') || clientRoles.some(i => i.type.symbol === 'data_curator') ?
                  <LinkContainer to="/reports">
                    <SidebarMenu.Nav.Link eventKey="reports" className="btn btn-light d-flex">
                      <SidebarMenu.Nav.Icon className='fs-4'><IoStatsChartSharp /></SidebarMenu.Nav.Icon>
                      <SidebarMenu.Nav.Title>{t('reports')}</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Link>
                  </LinkContainer>
                  : <></>}
                {uiConfig.menu.tacho ? (
                  <LinkContainer to="/tacho">
                    <SidebarMenu.Nav.Link eventKey="tacho" className="btn btn-light d-flex">
                      <SidebarMenu.Nav.Icon className='fs-4'><FaTachographDigital /></SidebarMenu.Nav.Icon>
                      <SidebarMenu.Nav.Title>{t('tacho')}</SidebarMenu.Nav.Title>
                    </SidebarMenu.Nav.Link>
                  </LinkContainer>) : <></>} */}

                {navbar.map(i => {
                  return (
                    <LinkContainer key={i.code} to={i.url}>
                      <SidebarMenu.Nav.Link eventKey={i.code} className="btn btn-light d-flex">
                        <SidebarMenu.Nav.Icon className='fs-4'>{menuIcon(i.code)}</SidebarMenu.Nav.Icon>
                        <SidebarMenu.Nav.Title>{t(i.code)}</SidebarMenu.Nav.Title>
                      </SidebarMenu.Nav.Link>
                    </LinkContainer>
                  )
                })}

                {isClient('ceskyRozhlas', costumer) ? <LinkContainer to="/autopujcovna" className="btn btn-light">
                  <SidebarMenu.Nav.Link eventKey="autopujcovna" className="btn btn-light d-flex">
                    <SidebarMenu.Nav.Icon className='fs-4' ><MdCarRental /></SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>Autopůjčovna</SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                </LinkContainer> : <></>}
              </SidebarMenu.Nav>
            </SidebarMenu.Body>
            <SidebarMenu.Footer className="sidebar-menu-body d-table">
              {/* <SidebarMenu.Text className="text-nowrap text-center">
              </SidebarMenu.Text> */}
              {pathname[1] !== 'profile' && pathname[1] !== 'config' && pathname[1] !== 'reports' && pathname[1] !== 'autopujcovna' ?
                <>
                  <DropdownButton
                    id="dropdown-button-navbar"
                    drop="up-centered"
                    variant="light"
                    title={<>
                      <span style={sideBar ? { width: '88px' } : { paddingLeft: '11px' }}>
                        <RiLayoutMasonryFill className="fs-4 icon-navbar-dropdown" title={t('display')} />
                      </span>
                      <span className="sidebar-menu-nav-title">
                        {sideBar ? t('display') : ''}
                      </span>
                    </>}
                  >
                    <LayoutWidget />
                    {leyoutWidgetAll() ? <></> : <Dropdown.Divider />}
                    <Dropdown.Item onClick={() => {
                      setDefaultLayout([Math.random(), page]);
                      // setUiConfig({ ...uiConfig, lockLayout: false });
                    }}>
                      <RxReload
                        style={{ width: '40px' }}
                      /> {t('resetDefault')}
                    </Dropdown.Item>
                    <Dropdown.Item active={false}
                      onClick={() => {
                        const lock = { ...uiConfig.lockLayout, [pathname[1]]: !userDetails.lockLayout[pathname[1]] }
                        setUiConfig({ ...uiConfig, lockLayout: lock })
                      }}
                    >
                      {userDetails.lockLayout[pathname[1]] ? <RxLockOpen2 style={{ width: '40px' }} /> : <RxLockClosed style={{ width: '40px' }} />}
                      {userDetails.lockLayout[pathname[1]] ? t('unlock') : t('lock')}
                    </Dropdown.Item>
                  </DropdownButton>
                  <hr className="dropdown-divider" role="separator"></hr>
                </>
                : <></>}



              <LinkContainer to="/config" className={pathname[1] === 'config' ? 'active' : ''}>
                <Button variant="light" className="footer-button-navbar mb-1" title={t('config')}>
                  <span style={sideBar ? { width: '88px' } : { paddingLeft: '11px' }}>
                    <IoSettings className="fs-4 icon-navbar-dropdown" />
                  </span>
                  <span className="sidebar-menu-nav-title">
                    {sideBar ? t('config') : ''}
                  </span>
                </Button>
              </LinkContainer>

              <Button variant="light" className="footer-button-navbar" title={t('notification')} onClick={() => { setNotification(true); WsSend({ method: 'getMemos', oid: 26, seen: null, pageSize: 10, page: 1 }) }}>
                <span style={sideBar ? { width: '88px' } : { paddingLeft: '11px' }} >
                  <FaBell className="fs-4 icon-navbar-dropdown" />
                </span>
                <span className="sidebar-menu-nav-title">
                  {sideBar ? t('notification') : ''}
                </span>
              </Button>

              <DropdownButton
                id="dropdown-button-navbar"
                drop="up-centered"
                variant="light"
                title={<>
                  <span style={sideBar ? { width: '88px' } : { paddingLeft: '8px' }}>
                    <Avatar size="30" textSizeRatio={1.75} maxInitials={1} round={true} className="sidebar-menu-nav-icon" name={userDetails.user.name} />
                  </span>
                  <span className="sidebar-menu-nav-title">
                    {sideBar ? userDetails.user.name : ''}
                  </span>
                </>}
              >
                <Dropdown.Item onClick={logout} >
                  <SlLogout style={{ width: '40px' }} />
                  {t('logout')}
                </Dropdown.Item>
                <LinkContainer to="/profile">
                  <Dropdown.Item>
                    <SlUser style={{ width: '40px' }} />
                    {t('profile')}
                  </Dropdown.Item>
                </LinkContainer>
              </DropdownButton>
            </SidebarMenu.Footer>
          </div>
        </SidebarMenu.Collapse>
      </SidebarMenu >

      <Offcanvas show={notification} onHide={() => { setNotification(false); WsSend({ method: 'unregister', id: 26 }) }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{t('notification')}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {userDetails.notification && userDetails.notification.list.length > 0 ? (userDetails.notification.list.map(n => {
            return <div key={n.id} className="bg-white d-flex mb-3 px-3 py-2 rounded-3 shadow">
              <div className="me-3 mt-2"><IoInformationCircleOutline className="fs-3 text-secondary" /></div>
              <div className="w-100">
                <div className="fw-bold">{n.subject}</div>
                <div >{n.body}</div>
                <div className="fs-7 text-secondary text-end mt-1">{format(parseISO(n.stamp), 'dd.MM.yyyy HH:mm')}</div>
              </div>
            </div>
          })) : (<div className="text-center">
            <FaBell className="fs-1" />
            <div>{t('notificationEmpty')}</div>
          </div>)}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
};

export default NavBar;